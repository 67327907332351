.house-image {
  width: 100%;
  border-radius: 8px;
}

.review-card {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 16px;
  max-width: 300px;
  border-radius: 8px;
}

.star-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.highlight {
  color: #FF3EA5; /* Adjust to match your brand color */
  text-decoration: underline;
  font-weight: bold;
}