.hero-section .highlight {
  color: #FFFB00;
}

.hero-section {
  position: relative;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 1;
}

.hero-section * {
  position: relative;
  z-index: 2;
}