.initial {
  position: absolute !important;
  /* background-color: white !important; */
  border-radius: 30px;
  /* color: black !important; */
  width: 60% !important;
  top: 5% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  transition: width 0.5s ease-in, height 0.5s ease-in,  background-color 0.5s ease-in, position 0.5s ease-in, top 0.5s ease-in, border-radius 0.5s ease-in, color 0.5s ease-in;
  z-index: 1000;
  padding: 5px 0;

  
}

.sticky {
  position: fixed;
  background-color: #FF3EA5 !important;
  left: 0 !important;
  top: 0% !important;
  /* transform: translateX(-0%) !important; */
  width: 100%;
  z-index: 1000;
  /* color: white !important; */
  margin: 0;
  transition: width 0.5s ease-in, height 0.5s ease-in, background-color 0.5s ease-in, left 0.5s ease-in, transform 0.5s ease-in, top 0.5s ease-in, border-radius 0.5s ease-in, color 0.5s ease-in;
}